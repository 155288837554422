// @flow
import React from 'react';
import Layout from '../../components/layout';
import G from '../../components/glossary-link';
import Link from '../../components/link';
import type { FrontMatter } from '../../utils/types';
import { withFrontMatter } from '../../components';
import route from '../../routes';

type Props = {
  frontMatter: FrontMatter,
};

class Aca_Core_Skills_Checklist extends React.Component<Props> {
  render() {
    const { frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <p>
          <Link to="https://www.canyoneering.net/wp-content/uploads/2020/04/Core-Skills-Checklist-4-22-20.pdf">
            Original document
          </Link>{' '}
          from the{' '}
          <G displayTerm={true} id="american-canyoneering-association">
            ACA
          </G>
          's{' '}
          <Link to="https://www.canyoneering.net/resources/">
            resource page
          </Link>
          .
        </p>
        <p>
          OBJECTIVE: Provide students with the fundamental canyoneering skills
          necessary to join a competently led group into canyons rated up to
          3A/B III and supervised introduction to technical canyoneering skills.
        </p>
        <h2>Logistics and Safety</h2>
        <ul>
          <li>
            Understand the <G>ACA Canyon Rating System</G> and different types
            of canyon terrain, as well as the special issues related to that
            terrain (i.e. <G id="swift-water">swift water canyons</G>,{' '}
            <G displayTerm={true} id="a-water-rating">
              dry and semi-dry canyons
            </G>
            , <G>slot canyons</G>,{' '}
            <G displayTerm={true} id="keeper-pothole">
              pothole escape problems
            </G>
            ,{' '}
            <G displayTerm={true} id="time-rating">
              long committing canyons
            </G>
            , permit requirements). ACA Canyon Rating System booklet can be
            found here:{' '}
            <Link to="http://www.canyoneering.net/docs/ratings.pdf">
              www.canyoneering.net/docs/ratings.pdf
            </Link>
          </li>
          <li>
            Using the ACA canyon rating system and canyon <G>beta</G>, estimate
            necessary <G>rope</G> lengths, list required technical gear and
            personal equipment for a selected canyon.
          </li>
          <li>
            Understand common causes of accidents in canyoneering and
            precautions that can be taken to avoid them.
          </li>
          <li>
            Understand how to find/read/interpret weather reports, cloud
            formations, changing weather patterns.
          </li>
          <li>
            Identify and describe variables that contribute to{' '}
            <G>flash flood</G> risk. Describe flash flood warning signs.
          </li>
          <li>
            Describe appropriate responses to an impending flash flood including
            identifying canyon exits and high ground.
          </li>
          <li>
            Demonstrate how to activate an SOS on a{' '}
            <G>Personal Locator Beacon</G> (PLB).
          </li>
        </ul>

        <h2>Leave No Trace Ethics</h2>
        <ul>
          <li>
            Read the ACA’s Canyons booklet{' '}
            <Link to="http://www.canyoneering.net/docs/booklet.pdf">
              www.canyoneering.net/docs/booklet.pdf
            </Link>
          </li>
          <li>Understand and apply low impact canyoneering practices.</li>
          <li>
            Understand and apply techniques to avoid <G>rope grooves</G>/damage
            in soft rock.
          </li>
          <li>
            Understand and apply appropriate{' '}
            <G displayTerm={true} id="wag-bag">
              methods
            </G>{' '}
            for human waste disposal.
          </li>
        </ul>

        <h2>Rope and Equipment Care</h2>
        <ul>
          <li>Understand and apply good rope care practices.</li>
          <li>Demonstrate coiling a rope; (a) Alpine coil, (b) Canyon coil.</li>
          <li>
            Stuff and use a <G>rope bag</G>.
          </li>
          <li>
            Understand and apply good equipment care and handling practices.
          </li>
        </ul>

        <h2>Basic Knots</h2>
        <ul>
          <li>
            Tie and inspect; <G id="figure-8-knot">Figure Eight knot</G>; (a){' '}
            <G id="figure-8-on-a-bight">on a Bight</G>, (b) Follow-through.
          </li>
          <li>
            Tie and inspect; <G>Ring Bend</G> in <G>webbing</G> (aka{' '}
            <G>Water knot</G>).
          </li>
          <li>
            Tie and inspect; <G>Clove hitch</G>.
          </li>
          <li>
            Tie and inspect; <G>Munter hitch</G> (aka Italian hitch, Dynamic
            hitch).
          </li>
          <li>
            Tie and inspect; <G>Girth hitch</G>.
          </li>
          <li>
            Tie and inspect; <G id="prusik">3-wrap Prusik hitch</G>.
          </li>
          <li>
            Tie and inspect; secure bend to connect two load-bearing ropes for
            rappelling (i.e. Double Fisherman bend,{' '}
            <G id="rethreaded-figure-8-bend">Figure Eight bend</G>).
          </li>
          <li>
            Tie and inspect; <G>Valdotain Tresse</G> (tied with a{' '}
            <G>VT Prusik</G> or equivalent).
          </li>
        </ul>

        <h2>Intro to Anchors and Rigging</h2>
        <ul>
          <li>
            Evaluate and rig existing fixed artificial <G>anchors</G> (
            <G>bolts</G>) using the acronym <G>EARNEST</G> (<G>Equalized</G>,{' '}
            <G>Angle</G>, <G>Redundant</G>, <G>No Extension</G>,{' '}
            <G id="solid">Strong</G>, <G>Timely</G>).
          </li>
          <li>
            Identify, Evaluate, and rig single-point <G>natural anchors</G>{' '}
            (i.e. tree, boulder, arch) using a) simple webbing wrap, b){' '}
            <G id="cinch-wrap">cinching wrap</G> (i.e.{' '}
            <G displayTerm={true} id="wrap-3-pull-2">
              wrap 2 pull 1
            </G>
            , Girth hitch).
            <br />
            Explain the pros and cons of each and demonstrate how to securely
            back up & test single point anchors.
          </li>
          <li>
            Set up and use a{' '}
            <G id="releasable-rigging-system">releasable/contingency system</G>{' '}
            for rappel. Explain the hazards/risks of using releasable systems
            and steps that should be taken to avoid accidents and mitigate
            risks.
          </li>
          <li>
            Identify & explain the variables and situations (scenarios) that
            dictate safely <G id="set-rope-length">setting rope length</G> to
            the height of a rappel. Demonstrate setting the rope length for safe
            descent for each scenario using appropriate communication and
            applicable rigging techniques.
          </li>
          <li>
            Set up and use <G>edge protection</G> to protect rope from abrasion
            and soft rock from rope grooves/damage.
          </li>
          <li>
            Identify, assess and mitigate basic{' '}
            <Link to={route.page_pull}>rope retrieval challenges</Link> (crossed
            ropes) including minimizing or avoiding rope grooves/damage to soft
            rock.
          </li>
          <li>
            Prep ropes for retrieval & successfully retrieve rope and prep for
            transport (Coil or <G id="rope-bag">Bag</G>).
          </li>
        </ul>

        <h2>Signals/Communication</h2>
        <ul>
          <li>
            Understand and use proper verbal for commands for{' '}
            <G id="belay">belaying</G>, <G id="rappel">rappelling</G>, and up/
            <G>down climbing</G>.
          </li>
          <li>
            Understand and use proper hand signals.{' '}
            <Link to="https://www.canyoneering.net/docs/signals.pdf">
              https://www.canyoneering.net/docs/signals.pdf
            </Link>
          </li>
        </ul>

        <h2>Climbing, Spotting, Handlines</h2>
        <ul>
          <li>
            Move on <G id="3rd-class-scrambling">3rd</G> and{' '}
            <G id="4th-class-scrambling">4th</G> class terrain (scrambling -
            rock climbing terrain).
          </li>
          <li>Move on 5th class terrain (rock climbing terrain).</li>
          <li>
            Demonstrate <G>chimneying</G>, <G>stemming</G>, and <G>bridging</G>{' '}
            techniques.
          </li>
          <li>
            <G>Spot</G> a climber; climbing up, climbing down.
          </li>
          <li>
            Understand and use <G>partner capture</G> (climbing down) and
            <G>partner assist</G> (climbing up) techniques for short vertical
            problems.
          </li>
          <li>
            Understand and apply appropriate use of a <G>hand line</G>.
          </li>
          <li>
            Understand and apply appropriate use of a friction hitch safety on a
            hand line.
          </li>
        </ul>
        <h2>Rappelling, Belaying, Self-Rescue</h2>
        <ul>
          <li>
            Understand and explain the risk and tasks associated with being the
            first rappeller.
          </li>
          <li>
            Set up and use a self belay with a Valdotain Tresse (tied with a VT
            or equivalent).
          </li>
          <li>
            Rig <G id="rappel-device">rappelling device</G> for correct friction
            on 8mm to 11mm ropes, <G id="single-rope-technique">single</G> and{' '}
            <G id="double-rope-technique">double</G> strand, understand the
            variables that contribute to friction requirements.
          </li>
          <li>
            Rappel; control speed while rappelling, apply brake to stop
            mid-rappel.
          </li>
          <li>
            Rappel using different the starting positions that include a)
            traditional (standing) b) <G id="sit-start">sitting</G> c) soft
            start (body friction/<G id="smear">smearing</G>); explain when such
            a start should be used.
          </li>
          <li>Add friction while rappelling.</li>
          <li>
            <G displayTerm={true} id="hard-lock-off">
              Lock off
            </G>{' '}
            rappel device while rappelling.
          </li>
          <li>
            <G id="soft-lock-off">Lock off</G> while rappelling using{' '}
            <G>leg wrap</G>.
          </li>
          <li>
            Demonstrate proper <G>bottom belay</G> (Fireman's belay).
          </li>
          <li>
            Demonstrate proper belay commands / verbal communication techniques.
          </li>
          <li>
            Demonstrate appropriate rappelling practices to minimize rope
            abrasion.
          </li>
          <li>
            Rappel with pack on and off. Describe scenarios for both situations.
          </li>
          <li>
            Use <G>rope grab</G> (<G>friction hitch</G> or{' '}
            <G id="ascender">mechanical ascender</G>) and <G>foot loop</G> to
            remove tension from rappel device to free stuck gear while
            rappelling.
          </li>
          <li>
            Perform controlled rappel up to 150'+ adding friction mid-rappel;
            communication - whistles or radios; hanging packs.
          </li>
        </ul>

        <h2>Swimming, Jumping, Water Slides</h2>
        <ul>
          <li>Create and use flotation with gear available.</li>
          <li>Swim 50 yards with gear, using floatation if necessary.</li>
          <li>
            Describe and identify <G>water hazards</G>.
          </li>
          <li>
            Safely perform water depth check; AKA "<G>dipstick</G>". ***No
            jumping!***
          </li>
          <li>
            Jumping into water (pool) using 'standard position' (feet first,
            arms slightly out). ***5 feet maximum height*** (5ft min depth).
          </li>
          <li>
            Perform{' '}
            <G displayTerm={true} id="floating-disconnect">
              wet rappel disconnect
            </G>{' '}
            in water.
          </li>
          <li>Water slides; identify hazards, correct body position.</li>
        </ul>

        <h3>Recommendations After Training</h3>
        <ul>
          <li>
            Practice technical skills in low-risk conditions, such as on clean
            and low angle "slab" type environments or on vertical terrain with
            an effective Top or Bottom (Fireman’s) Belay.
          </li>
          <li>
            Join well organized groups with competent Leader(s) descending
            easier canyons rated up to 3A/B III.
          </li>
        </ul>
      </Layout>
    );
  }
}

export default withFrontMatter('core-skills-checklist')(
  Aca_Core_Skills_Checklist
);
